import {
  UserInfo,
  ConversationRequest,
  Conversation,
  ChatMessage,
  CosmosDBHealth,
  CosmosDBStatus,
} from './models';
import { chatHistorySampleData } from '../constants/chatHistory';

export async function conversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal
): Promise<Response> {
  const response = await fetch('/conversation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      messages: options.messages,
    }),
    signal: abortSignal,
  });

  return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
  const response = await fetch('/.auth/me');
  if (!response.ok) {
    console.log('No identity provider found. Access to chat will be blocked.');
    return [];
  }

  const payload = await response.json();
  return payload;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null =>
  // Make initial API call here

  // return null;
  chatHistorySampleData;

export const historyList = async (
  offset = 0
): Promise<Conversation[] | null> => {
  const response = await fetch(`/history/list?offset=${offset}`, {
    method: 'GET',
  })
    .then(async res => {
      const payload = await res.json();
      if (!Array.isArray(payload)) {
        // console.error('There was an issue fetching your data.');
        return null;
      }
      const conversations: Conversation[] = await Promise.all(
        payload.map(async (conv: any) => {
          let convMessages: ChatMessage[] = [];
          convMessages = await historyRead(conv.id)
            .then(res => res)
            .catch(err =>
              // console.error('error fetching messages: ', err);
              []
            );
          const conversation: Conversation = {
            id: conv.id,
            title: conv.title,
            date: conv.createdAt,
            messages: convMessages,
          };
          return conversation;
        })
      );
      return conversations;
    })
    .catch(
      err =>
        // console.error('There was an issue fetching your data.');
        null
    );

  return response;
};

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
  const response = await fetch('/history/read', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async res => {
      if (!res) {
        return [];
      }
      const payload = await res.json();
      const messages: ChatMessage[] = [];
      if (payload?.messages) {
        payload.messages.forEach((msg: any) => {
          const message: ChatMessage = {
            id: msg.id,
            role: msg.role,
            date: msg.createdAt,
            content: msg.content,
            feedback: msg.feedback ?? undefined,
          };
          messages.push(message);
        });
      }
      return messages;
    })
    .catch(err =>
      // console.error('There was an issue fetching your data.');
      []
    );
  return response;
};

export const historyGenerate = async (
  options: ConversationRequest,
  abortSignal: AbortSignal,
  convId?: string
): Promise<Response> => {
  let body;
  if (convId) {
    body = JSON.stringify({
      conversation_id: convId,
      messages: options.messages,
    });
  } else {
    body = JSON.stringify({
      messages: options.messages,
    });
  }
  const response = await fetch('/history/generate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    signal: abortSignal,
  })
    .then(res => res)
    .catch(
      err =>
        // console.error('There was an issue fetching your data.');
        new Response()
    );
  return response;
};

export const historyUpdate = async (
  messages: ChatMessage[],
  convId: string
): Promise<Response> => {
  const response = await fetch('/history/update', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
      messages,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async res => res)
    .catch(err => {
      // console.error('There was an issue fetching your data.');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDelete = async (convId: string): Promise<Response> => {
  const response = await fetch('/history/delete', {
    method: 'DELETE',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res)
    .catch(err => {
      // console.error('There was an issue fetching your data.');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDeleteAll = async (): Promise<Response> => {
  const response = await fetch('/history/delete_all', {
    method: 'DELETE',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res)
    .catch(err => {
      // console.error('There was an issue fetching your data.');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyClear = async (convId: string): Promise<Response> => {
  const response = await fetch('/history/clear', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res)
    .catch(err => {
      // console.error('There was an issue fetching your data.');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyRename = async (
  convId: string,
  title: string
): Promise<Response> => {
  const response = await fetch('/history/rename', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
      title,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res)
    .catch(err => {
      // console.error('There was an issue fetching your data.');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
  const response = await fetch('/history/ensure', {
    method: 'GET',
  })
    .then(async res => {
      const respJson = await res.json();
      let formattedResponse;
      if (respJson.message) {
        formattedResponse = CosmosDBStatus.Working;
      } else if (res.status === 500) {
        formattedResponse = CosmosDBStatus.NotWorking;
      } else {
        formattedResponse = CosmosDBStatus.NotConfigured;
      }
      if (!res.ok) {
        return {
          cosmosDB: false,
          status: formattedResponse,
        };
      }
      return {
        cosmosDB: true,
        status: formattedResponse,
      };
    })
    .catch(err =>
      // console.error('There was an issue fetching your data.');
      ({
        cosmosDB: false,
        status: err,
      })
    );
  return response;
};

export const frontendSettings = async (): Promise<Response | null> => {
  const response = await fetch('/frontend_settings', {
    method: 'GET',
  })
    .then(res => res.json())
    .catch(
      err =>
        // console.error('There was an issue fetching your data.');
        null
    );

  return response;
};

export const historyMessageFeedback = async (
  messageId: string,
  feedback: string
): Promise<Response> => {
  const response = await fetch('/history/message_feedback', {
    method: 'POST',
    body: JSON.stringify({
      message_id: messageId,
      message_feedback: feedback,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res)
    .catch(err => {
      // console.error('There was an issue logging feedback.');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const sendChatMessage = async (message: string): Promise<any> => {
  const userMessage = message.trim();

  if (!userMessage) {
    // Return null or an appropriate value if the message is empty
    return null;
  }

  // Prepare the request headers
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');
  myHeaders.append(
    'Ocp-Apim-Subscription-Key',
    '48b17b545d0345e386b16d60f3ff1fac'
  );

  function generateGUID() {
    let sessionGUID = localStorage.getItem('sessionGUID');
    if (sessionGUID === null) {
      sessionGUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      localStorage.setItem('sessionGUID', sessionGUID);
    }
    console.log("Generated or Retrieved Session GUID:", sessionGUID);
    return sessionGUID;
  }
  

  // Prepare the request body
  const raw = JSON.stringify({
    chatRequest: {
      id: generateGUID(), // This will call the generateGUID function to get a new GUID
      message: userMessage,
      token: null,
    },
  });
  

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      'https://muks-dvt-openai-prod-apim.azure-api.net/openai/chat',
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    // console.error('Error:', error);
    // Return null or throw the error based on how you want to handle errors
    return null;
  }
};
