import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { CopyRegular } from '@fluentui/react-icons';
import { Dialog, Stack, TextField } from '@fluentui/react';
import DVT from '../../assets/DVT.svg';
// import { HistoryButton, ShareButton } from '../../components/common/Button';
import { AppStateContext } from '../../state/AppProvider';
// import { CosmosDBStatus } from '../../api';
import styles from './Layout.module.scss';

const Layout = () => {
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
  const [copyClicked, setCopyClicked] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<string>('Copy URL');
  const appStateContext = useContext(AppStateContext);

  /* const handleShareClick = () => {
    setIsSharePanelOpen(true);
  }; */

  const handleSharePanelDismiss = () => {
    setIsSharePanelOpen(false);
    setCopyClicked(false);
    setCopyText('Copy URL');
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopyClicked(true);
  };

  /* const handleHistoryClick = () => {
    appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' });
  }; */

  useEffect(() => {
    if (copyClicked) {
      setCopyText('Copied URL');
    }
  }, [copyClicked]);

  useEffect(() => {}, [appStateContext?.state.isCosmosDBAvailable.status]);

  return (
    <div className="flex flex-col h-full">
      <header className="bg-tertiary p-2 md:p-6" role="banner">
        <Stack
          horizontal
          verticalAlign="center"
          className="flex justify-evenly md:justify-between"
        >
          <Stack horizontal verticalAlign="center">
            <Link
              to="https://www.dvtsoftware.com/"
              className="flex align-center ml-1 mr-2 md:ml-4 decoration-none"
            >
              <img
                src={DVT}
                alt="DVT logo"
                className="w-20 md:w-32 md:ml-6"
                aria-hidden="true"
              />
            </Link>
          </Stack>
          {/* <Stack horizontal tokens={{ childrenGap: 4 }}>
            {appStateContext?.state.isCosmosDBAvailable?.status !==
              CosmosDBStatus.NotConfigured && (
              <HistoryButton
                onClick={handleHistoryClick}
                text={
                  appStateContext?.state?.isChatHistoryOpen
                    ? 'Hide chat history'
                    : 'Show chat history'
                }
              />
            )}
            <ShareButton onClick={handleShareClick} />
          </Stack> */}
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Link
              to="https://www.dvtsoftware.com/contact-us"
              className="text-white"
            >
              <div className={styles.contactButton}>Contact Us</div>
            </Link>
          </Stack>
        </Stack>
      </header>
      <Outlet />
      <Dialog
        onDismiss={handleSharePanelDismiss}
        hidden={!isSharePanelOpen}
        styles={{
          main: [
            {
              selectors: {
                '@media (min-width: 480px)': {
                  maxWidth: '600px',
                  background: '#FFFFFF',
                  boxShadow:
                    '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  maxHeight: '200px',
                  minHeight: '100px',
                },
              },
            },
          ],
        }}
        dialogContentProps={{
          title: 'Share the web app',
          showCloseButton: true,
        }}
      >
        <Stack horizontal verticalAlign="center" style={{ gap: '8px' }}>
          <TextField defaultValue={window.location.href} readOnly />
          <div
            role="button"
            tabIndex={0}
            aria-label="Copy"
            onClick={handleCopyClick}
            onKeyDown={e =>
              e.key === 'Enter' || e.key === ' ' ? handleCopyClick() : null
            }
          >
            <CopyRegular />
            <span>{copyText}</span>
          </div>
        </Stack>
      </Dialog>
    </div>
  );
};

export default Layout;
