import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from 'react';
import {
  CommandBarButton,
  IconButton,
  Dialog,
  DialogType,
  Stack,
} from '@fluentui/react';
import { ShieldLockRegular, ErrorCircleRegular } from '@fluentui/react-icons';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import uuid from 'react-uuid';
import { isEmpty } from 'lodash-es';
import DOMPurify from 'dompurify';

import { useBoolean } from '@fluentui/react-hooks';
import DVTAI from '../../assets/DVTAI.svg';
import { XSSAllowTags } from '../../constants/xssAllowTags';

import {
  ChatMessage,
  ConversationRequest,
  conversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  getUserInfo,
  Conversation,
  historyGenerate,
  historyUpdate,
  historyClear,
  ChatHistoryLoadingState,
  CosmosDBStatus,
  ErrorMessage,
  sendChatMessage,
} from '../../api';
import { Answer } from '../../components/Answer';
import { QuestionInput } from '../../components/QuestionInput';
import { ChatHistoryPanel } from '../../components/ChatHistory/ChatHistoryPanel';
import { AppStateContext } from '../../state/AppProvider';

const enum messageStatus {
  NotRunning = 'Not Running',
  Processing = 'Processing',
  Done = 'Done',
}

const Chat = () => {
  const appStateContext = useContext(AppStateContext);
  const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [activeCitation, setActiveCitation] = useState<Citation>();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  const abortFuncs = useRef([] as AbortController[]);
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [processMessages, setProcessMessages] = useState<messageStatus>(
    messageStatus.NotRunning
  );
  const [clearingChat, setClearingChat] = useState<boolean>(false);
  const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
  const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();

  const errorDialogContentProps = {
    type: DialogType.close,
    title: errorMsg?.title,
    closeButtonAriaLabel: 'Close',
    subText: errorMsg?.subtitle,
  };

  const modalProps = {
    titleAriaId: 'labelId',
    subtitleAriaId: 'subTextId',
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };

  const [ASSISTANT, TOOL, ERROR] = ['assistant', 'tool', 'error'];

  useEffect(() => {
    if (
      appStateContext?.state.isCosmosDBAvailable?.status ===
        CosmosDBStatus.NotWorking &&
      appStateContext.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Fail &&
      hideErrorDialog
    ) {
      const subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`;
      setErrorMsg({
        title: 'Chat history is not enabled',
        subtitle,
      });
      toggleErrorDialog();
    }
  }, [appStateContext?.state.isCosmosDBAvailable]);

  const handleErrorDialogClose = () => {
    toggleErrorDialog();
    setTimeout(() => {
      setErrorMsg(null);
    }, 500);
  };

  useEffect(() => {
    setIsLoading(
      appStateContext?.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Loading
    );
  }, [appStateContext?.state.chatHistoryLoadingState]);

  const getUserInfoList = async () => {
    if (!AUTH_ENABLED) {
      setShowAuthMessage(false);
      return;
    }
    const userInfoList = await getUserInfo();
    if (userInfoList.length === 0 && window.location.hostname !== '127.0.0.1') {
      setShowAuthMessage(true);
    } else {
      setShowAuthMessage(false);
    }
  };

  let assistantMessage = {} as ChatMessage;
  let toolMessage = {} as ChatMessage;
  let assistantContent = '';

  const processResultMessage = (
    resultMessage: ChatMessage,
    userMessage: ChatMessage,
    conversationId?: string
  ) => {
    if (resultMessage.role === ASSISTANT) {
      assistantContent += resultMessage.content;
      assistantMessage = resultMessage;
      assistantMessage.content = assistantContent;
    }

    if (resultMessage.role === TOOL) toolMessage = resultMessage;

    if (!conversationId) {
      isEmpty(toolMessage)
        ? setMessages([...messages, userMessage, assistantMessage])
        : setMessages([
            ...messages,
            userMessage,
            toolMessage,
            assistantMessage,
          ]);
    } else {
      isEmpty(toolMessage)
        ? setMessages([...messages, assistantMessage])
        : setMessages([...messages, toolMessage, assistantMessage]);
    }
  };

  const makeApiRequestWithoutCosmosDB = async (
    question: string,
    conversationId?: string
  ) => {
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: 'user',
      content: question,
      date: new Date().toISOString(),
    };

    let conversation: Conversation | null | undefined;
    if (!conversationId) {
      conversation = {
        id: conversationId ?? uuid(),
        title: question,
        messages: [userMessage],
        date: new Date().toISOString(),
      };
    } else {
      conversation = appStateContext?.state?.currentChat;
      if (!conversation) {
        console.error('Conversation not found.');
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          a => a !== abortController
        );
        return;
      }
      conversation.messages.push(userMessage);
    }

    appStateContext?.dispatch({
      type: 'UPDATE_CURRENT_CHAT',
      payload: conversation,
    });
    setMessages(conversation.messages);

    const request: ConversationRequest = {
      messages: [
        ...conversation.messages.filter(answer => answer.role !== ERROR),
      ],
    };

    let result = {} as ChatResponse;
    try {
      const response = await conversationApi(request, abortController.signal);
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = '';

        while (true) {
          setProcessMessages(messageStatus.Processing);
          const { done, value } = await reader.read();
          if (done) break;

          const text = new TextDecoder('utf-8').decode(value);
          const objects = text.split('\n');
          objects.forEach(obj => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              result.choices[0].messages.forEach(obj => {
                obj.id = result.id;
                obj.date = new Date().toISOString();
              });
              setShowLoadingMessage(false);
              result.choices[0].messages.forEach(resultObj => {
                processResultMessage(resultObj, userMessage, conversationId);
              });
              runningText = '';
            } catch {}
          });
        }
        conversation.messages.push(toolMessage, assistantMessage);
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: conversation,
        });
        setMessages([...messages, toolMessage, assistantMessage]);
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage =
          'An error occurred. Please try again. If the problem persists, please contact the site administrator.';
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === 'string') {
          errorMessage = result.error;
        }
        const errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString(),
        };
        conversation.messages.push(errorChatMsg);
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: conversation,
        });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        a => a !== abortController
      );
      setProcessMessages(messageStatus.Done);
    }

    return abortController.abort();
  };

  // This is a custom chat function for now
  const makeApiRequestWithoutCustomApi = async (
    question: string,
    conversationId?: string
  ) => {
    setIsLoading(true);
    setShowLoadingMessage(true);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: 'user',
      content: question,
      date: new Date().toISOString(),
    };

    setMessages(prevMessages => [...prevMessages, userMessage]);

    try {
      const response = await sendChatMessage(question);

      if (response && Array.isArray(response)) {
        const botResponse: ChatMessage = {
          id: uuid(),
          role: 'assistant',
          content: response[1].message,
          date: new Date().toISOString(),
        };

        setMessages(prevMessages => [...prevMessages, botResponse]);
      }
    } catch (e) {
      setMessages(prevMessages => [...prevMessages, userMessage]);
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
    }
  };

  const makeApiRequestWithCosmosDB = async (
    question: string,
    conversationId?: string
  ) => {
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: 'user',
      content: question,
      date: new Date().toISOString(),
    };

    // api call params set here (generate)
    let request: ConversationRequest;
    let conversation;
    if (conversationId) {
      conversation = appStateContext?.state?.chatHistory?.find(
        conv => conv.id === conversationId
      );
      if (!conversation) {
        console.error('Conversation not found.');
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          a => a !== abortController
        );
        return;
      }
      conversation.messages.push(userMessage);
      request = {
        messages: [
          ...conversation.messages.filter(answer => answer.role !== ERROR),
        ],
      };
    } else {
      request = {
        messages: [userMessage].filter(answer => answer.role !== ERROR),
      };
      setMessages(request.messages);
    }
    let result = {} as ChatResponse;
    try {
      const response = conversationId
        ? await historyGenerate(request, abortController.signal, conversationId)
        : await historyGenerate(request, abortController.signal);
      if (!response?.ok) {
        const errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content:
            "There was an error generating a response. Chat history can't be saved at this time. If the problem persists, please contact the site administrator.",
          date: new Date().toISOString(),
        };
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            conv => conv.id === conversationId
          );
          if (!resultConversation) {
            console.error('Conversation not found.');
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              a => a !== abortController
            );
            return;
          }
          resultConversation.messages.push(errorChatMsg);
        } else {
          setMessages([...messages, userMessage, errorChatMsg]);
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            a => a !== abortController
          );
          return;
        }
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: resultConversation,
        });
        setMessages([...resultConversation.messages]);
        return;
      }
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = '';

        while (true) {
          setProcessMessages(messageStatus.Processing);
          const { done, value } = await reader.read();
          if (done) break;

          const text = new TextDecoder('utf-8').decode(value);
          const objects = text.split('\n');
          objects.forEach(obj => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              result.choices[0].messages.forEach(obj => {
                obj.id = result.id;
                obj.date = new Date().toISOString();
              });
              setShowLoadingMessage(false);
              result.choices[0].messages.forEach(resultObj => {
                processResultMessage(resultObj, userMessage, conversationId);
              });
              runningText = '';
            } catch {}
          });
        }

        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            conv => conv.id === conversationId
          );
          if (!resultConversation) {
            console.error('Conversation not found.');
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              a => a !== abortController
            );
            return;
          }
          isEmpty(toolMessage)
            ? resultConversation.messages.push(assistantMessage)
            : resultConversation.messages.push(toolMessage, assistantMessage);
        } else {
          resultConversation = {
            id: result.history_metadata.conversation_id,
            title: result.history_metadata.title,
            messages: [userMessage],
            date: result.history_metadata.date,
          };
          isEmpty(toolMessage)
            ? resultConversation.messages.push(assistantMessage)
            : resultConversation.messages.push(toolMessage, assistantMessage);
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            a => a !== abortController
          );
          return;
        }
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: resultConversation,
        });
        isEmpty(toolMessage)
          ? setMessages([...messages, assistantMessage])
          : setMessages([...messages, toolMessage, assistantMessage]);
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage =
          'An error occurred. Please try again. If the problem persists, please contact the site administrator.';
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === 'string') {
          errorMessage = result.error;
        }
        const errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString(),
        };
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            conv => conv.id === conversationId
          );
          if (!resultConversation) {
            console.error('Conversation not found.');
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              a => a !== abortController
            );
            return;
          }
          resultConversation.messages.push(errorChatMsg);
        } else {
          if (!result.history_metadata) {
            console.error('Error retrieving data.', result);
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              a => a !== abortController
            );
            return;
          }
          resultConversation = {
            id: result.history_metadata.conversation_id,
            title: result.history_metadata.title,
            messages: [userMessage],
            date: result.history_metadata.date,
          };
          resultConversation.messages.push(errorChatMsg);
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            a => a !== abortController
          );
          return;
        }
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: resultConversation,
        });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        a => a !== abortController
      );
      setProcessMessages(messageStatus.Done);
    }
    return abortController.abort();
  };

  const clearChat = async () => {
    setClearingChat(true);
    if (
      appStateContext?.state.currentChat?.id &&
      appStateContext?.state.isCosmosDBAvailable.cosmosDB
    ) {
      const response = await historyClear(
        appStateContext?.state.currentChat.id
      );
      if (!response.ok) {
        setErrorMsg({
          title: 'Error clearing current chat',
          subtitle:
            'Please try again. If the problem persists, please contact the site administrator.',
        });
        toggleErrorDialog();
      } else {
        appStateContext?.dispatch({
          type: 'DELETE_CURRENT_CHAT_MESSAGES',
          payload: appStateContext?.state.currentChat.id,
        });
        appStateContext?.dispatch({
          type: 'UPDATE_CHAT_HISTORY',
          payload: appStateContext?.state.currentChat,
        });
        setActiveCitation(undefined);
        setIsCitationPanelOpen(false);
        setMessages([]);
      }
    }
    setClearingChat(false);
  };

  const newChat = () => {
    setProcessMessages(messageStatus.Processing);
    setMessages([]);
    setIsCitationPanelOpen(false);
    setActiveCitation(undefined);
    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
    setProcessMessages(messageStatus.Done);
  };

  const stopGenerating = () => {
    abortFuncs.current.forEach(a => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (appStateContext?.state.currentChat) {
      setMessages(appStateContext.state.currentChat.messages);
    } else {
      setMessages([]);
    }
  }, [appStateContext?.state.currentChat]);

  useLayoutEffect(() => {
    const saveToDB = async (messages: ChatMessage[], id: string) => {
      const response = await historyUpdate(messages, id);
      return response;
    };

    if (
      appStateContext &&
      appStateContext.state.currentChat &&
      processMessages === messageStatus.Done
    ) {
      if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
        if (!appStateContext?.state.currentChat?.messages) {
          console.error('Failure fetching current chat state.');
          return;
        }
        saveToDB(
          appStateContext.state.currentChat.messages,
          appStateContext.state.currentChat.id
        )
          .then(res => {
            if (!res.ok) {
              const errorMessage =
                "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
              const errorChatMsg: ChatMessage = {
                id: uuid(),
                role: ERROR,
                content: errorMessage,
                date: new Date().toISOString(),
              };
              if (!appStateContext?.state.currentChat?.messages) {
                const err: Error = {
                  ...new Error(),
                  message: 'Failure fetching current chat state.',
                };
                throw err;
              }
              setMessages([
                ...appStateContext?.state.currentChat?.messages,
                errorChatMsg,
              ]);
            }
            return res as Response;
          })
          .catch(err => {
            console.error('Error: ', err);
            const errRes: Response = {
              ...new Response(),
              ok: false,
              status: 500,
            };
            return errRes;
          });
      } else {
      }
      appStateContext?.dispatch({
        type: 'UPDATE_CHAT_HISTORY',
        payload: appStateContext.state.currentChat,
      });
      setMessages(appStateContext.state.currentChat.messages);
      setProcessMessages(messageStatus.NotRunning);
    }
  }, [processMessages]);

  useEffect(() => {
    if (AUTH_ENABLED !== undefined) getUserInfoList();
  }, [AUTH_ENABLED]);

  useLayoutEffect(() => {
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'smooth' });
  }, [showLoadingMessage, processMessages]);

  const onShowCitation = (citation: Citation) => {
    setActiveCitation(citation);
    setIsCitationPanelOpen(true);
  };

  const onViewSource = (citation: Citation) => {
    if (citation.url && !citation.url.includes('blob.core')) {
      window.open(citation.url, '_blank');
    }
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message?.role && message?.role === 'tool') {
      try {
        const toolMessage = JSON.parse(message.content) as ToolMessageContent;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  const disabledButton = () =>
    isLoading ||
    (messages && messages.length === 0) ||
    clearingChat ||
    appStateContext?.state.chatHistoryLoadingState ===
      ChatHistoryLoadingState.Loading;

  return (
    <div
      className="flex flex-col px-4 md:px-0 overflow-x-hidden bg-tertiary h-full overflow-hidden"
      role="main"
    >
      {showAuthMessage ? (
        <Stack className="flex flex-col justify-center items-center text-white">
          <ShieldLockRegular
            style={{ color: 'darkorange' }}
            className="w-20 md:w-64 h-auto"
          />

          <h1 className="text-xl flex items-end text-center mt-8 font-bold">
            Authentication Not Configured
          </h1>
          <h2 className="mt-10 text-md flex flex-col md:flex-row text-center">
            This app does not have authentication configured. Please add an
            identity provider by finding your app in the
            <a
              href="https://portal.azure.com/"
              target="_blank"
              rel="noreferrer"
            >
              Azure Portal
            </a>
            and following
            <a
              href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization"
              target="_blank"
              rel="noreferrer"
            >
              these instructions.
            </a>
          </h2>
          <h2
            className="text-md flex items-end text-center mt-10"
            style={{ fontSize: '20px' }}
          >
            <strong>
              Authentication configuration takes a few minutes to apply.{' '}
            </strong>
          </h2>
          <h2
            className="text-md flex items-end text-center"
            style={{ fontSize: '20px' }}
          >
            <strong>
              If you deployed in the last 10 minutes, please wait and reload the
              page after 10 minutes.
            </strong>
          </h2>
        </Stack>
      ) : (
        <Stack
          horizontal
          className="flex flex-1 flex-col items-center overflow-y-auto"
        >
          <div className="flex flex-1 flex-col align-center justify-between overflow-y-auto w-full">
            {!messages || messages.length < 1 ? (
              <Stack className="flex flex-col justify-center items-center h-full">
                <img
                  src={DVTAI}
                  className="w-10 md:w-20 h-auto"
                  aria-hidden="true"
                />
                <h1 className="font-bold flex text-4xl mt-6 text-white text-center">
                  How can I help you today?
                </h1>
                <h2 className="flex mt-5 w-full md:w-1/3 items-end text-base text-white text-center">
                  Experience our DVT Generative AI BOT built using Azure. Chat
                  and have your questions answered in regard to DVT, our
                  services, people and capabilities. If our BOT is unable to
                  answer your question, please do contact us and one of our REAL
                  DVT people will be in touch.
                </h2>
              </Stack>
            ) : (
              <div
                className="overflow-y-auto mt-6 w-full flex flex-col"
                role="log"
                id="chat-list"
              >
                {messages.map((answer, index) => (
                  <React.Fragment key={index}>
                    {answer.role === 'user' ? (
                      <div className="flex justify-center w-full">
                        <div className="flex flex-col w-full md:w-1/2 px-3">
                          <div className="flex flex-row justify-start">
                            <div
                              className="flex flex-col mb-8 text-white" // px-10 md:px-80
                              tabIndex={0}
                            >
                              <div className="flex flex-row">
                                <svg
                                  width="24"
                                  height="24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="12"
                                    fill="#FFC72C"
                                  />
                                  <text
                                    x="12"
                                    y="12"
                                    dy="3.5"
                                    fontFamily="Arial"
                                    fontSize="12"
                                    textAnchor="middle"
                                    fill="white"
                                  >
                                    U
                                  </text>
                                </svg>
                                <span className="ml-2 font-bold">You</span>
                              </div>
                              <div className="text-base ml-8 mb-6">
                                {answer.content}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : answer.role === 'assistant' ? (
                      <div className="flex justify-center w-full">
                        <div className="flex flex-col w-full md:w-1/2 px-3">
                          <div className="flex flex-row justify-start">
                            <div className="flex flex-col mb-4 text-white">
                              {/* px-10 md:px-80 */}
                              <div className="flex flex-row">
                                <img src={DVTAI} className="w-6 h-auto" />
                                <span className="ml-2 font-bold">DVT AI</span>
                              </div>
                              <div className="ml-8 mb-6">
                                <Answer
                                  answer={{
                                    answer: answer.content,
                                    citations: parseCitationFromMessage(
                                      messages[index - 1]
                                    ),
                                    message_id: answer.id,
                                    feedback: answer.feedback,
                                  }}
                                  onCitationClicked={c => onShowCitation(c)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : answer.role === ERROR ? (
                      <div className="flex justify-center w-full">
                        <div className="flex flex-col w-full md:w-1/2 px-3">
                          <div className="flex flex-row justify-start">
                            <div className="">
                              {/* px-10 md:px-80 */}
                              <Stack horizontal className="">
                                <ErrorCircleRegular
                                  className=""
                                  style={{ color: 'rgba(182, 52, 67, 1)' }}
                                />
                                <span>Error</span>
                              </Stack>
                              <span className="">{answer.content}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}

                {showLoadingMessage && (
                  <div className="flex justify-center w-full">
                    <div className="flex flex-col w-full md:w-1/2 px-3">
                      <div className="flex flex-row justify-start">
                        <div className="">
                          {/* px-10 md:px-80 */}
                          <div className="flex flex-row text-white">
                            <img src={DVTAI} className="w-6 h-auto" />
                            <span className="ml-2 font-bold">DVT AI</span>
                          </div>

                          <div className="text-base animate-pulse ml-8 mt-2">
                            <svg
                              width="10"
                              height="10"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="5" cy="5" r="5" fill="white" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={chatMessageStreamEnd} />
              </div>
            )}

            <div className="flex justify-center w-full">
              <Stack
                horizontal
                className="flex flex-col md:px-10 py-10 w-full md:w-1/2"
              >
                <div className="flex flex-row">
                  <Stack className="flex flex-col justify-between pr-2">
                    {appStateContext?.state.isCosmosDBAvailable?.status !==
                      CosmosDBStatus.NotConfigured && (
                      <CommandBarButton
                        role="button"
                        styles={{
                          icon: {
                            color: '#FFFFFF',
                          },
                          iconDisabled: {
                            color: '#BDBDBD !important',
                          },
                          root: {
                            color: '#FFFFFF',
                            background:
                              'radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)',
                            width: '20px',
                            height: '20px',
                          },
                          rootDisabled: {
                            background: '#F0F0F0',
                          },
                        }}
                        className="hover:bg-transparent"
                        iconProps={{ iconName: 'Add' }}
                        onClick={newChat}
                        disabled={disabledButton()}
                        aria-label="start a new chat button"
                      />
                    )}
                    <CommandBarButton
                      role="button"
                      styles={{
                        icon: {
                          color: '#FFFFFF',
                        },
                        iconDisabled: {
                          color: '#BDBDBD !important',
                        },
                        root: {
                          color: '#FFFFFF',
                          background:
                            'radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)',
                          width: '20px',
                          height: '20px',
                        },
                        rootDisabled: {
                          background: '#F0F0F0',
                        },
                      }}
                      className={
                        appStateContext?.state.isCosmosDBAvailable?.status !==
                        CosmosDBStatus.NotConfigured
                          ? 'hover:bg-transparent'
                          : 'hover:bg-transparent'
                      }
                      iconProps={{ iconName: 'Broom' }}
                      onClick={
                        appStateContext?.state.isCosmosDBAvailable?.status !==
                        CosmosDBStatus.NotConfigured
                          ? clearChat
                          : newChat
                      }
                      disabled={disabledButton()}
                      aria-label="clear chat button"
                    />
                    <Dialog
                      hidden={hideErrorDialog}
                      onDismiss={handleErrorDialogClose}
                      dialogContentProps={errorDialogContentProps}
                      modalProps={modalProps}
                    />
                  </Stack>

                  <QuestionInput
                    clearOnSend
                    placeholder="Message DVT"
                    disabled={isLoading}
                    onSend={(question, id) => {
                      appStateContext?.state.isCosmosDBAvailable?.cosmosDB
                        ? makeApiRequestWithCosmosDB(question, id)
                        : makeApiRequestWithoutCustomApi(question, id); // makeApiRequestWithoutCosmosDB(question, id)
                    }}
                    conversationId={
                      appStateContext?.state.currentChat?.id
                        ? appStateContext?.state.currentChat?.id
                        : undefined
                    }
                    isLoading={isLoading}
                    stopGenerating={stopGenerating}
                  />
                </div>
              </Stack>
            </div>
          </div>

          {/* Citation Panel */}
          {messages &&
            messages.length > 0 &&
            isCitationPanelOpen &&
            activeCitation && (
              <Stack.Item
                className=""
                tabIndex={0}
                role="tabpanel"
                aria-label="Citations Panel"
              >
                <Stack
                  aria-label="Citations Panel Header Container"
                  horizontal
                  className=""
                  horizontalAlign="space-between"
                  verticalAlign="center"
                >
                  <span aria-label="Citations" className="">
                    Citations
                  </span>
                  <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    aria-label="Close citations panel"
                    onClick={() => setIsCitationPanelOpen(false)}
                  />
                </Stack>
                <h5
                  className=""
                  tabIndex={0}
                  title={
                    activeCitation.url &&
                    !activeCitation.url.includes('blob.core')
                      ? activeCitation.url
                      : activeCitation.title ?? ''
                  }
                  onClick={() => onViewSource(activeCitation)}
                >
                  {activeCitation.title}
                </h5>
                <div tabIndex={0}>
                  <ReactMarkdown
                    linkTarget="_blank"
                    className=""
                    children={DOMPurify.sanitize(activeCitation.content, {
                      ALLOWED_TAGS: XSSAllowTags,
                    })}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
              </Stack.Item>
            )}
          {appStateContext?.state.isChatHistoryOpen &&
            appStateContext?.state.isCosmosDBAvailable?.status !==
              CosmosDBStatus.NotConfigured && <ChatHistoryPanel />}
        </Stack>
      )}
    </div>
  );
};

export default Chat;
