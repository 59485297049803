import React, { useState } from 'react';
import { Stack, TextField } from '@fluentui/react';
import { SendRegular, SquareRegular } from '@fluentui/react-icons';
import Send from '../../assets/Send.svg';

interface Props {
  onSend: (question: string, id?: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  conversationId?: string;
  isLoading: boolean;
  stopGenerating: () => void;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder = '',
  clearOnSend = true,
  conversationId = '',
  isLoading,
  stopGenerating,
}: Props) => {
  const [question, setQuestion] = useState<string>('');

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    if (conversationId) {
      onSend(question, conversationId);
    } else {
      onSend(question);
    }

    if (clearOnSend) {
      setQuestion('');
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setQuestion(newValue || '');
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <Stack horizontal className="w-full relative">
      <div className="relative w-full inline-block">
        <TextField
          id="message-box"
          style={{
            height: '60px',
          }}
          maxLength={150}
          placeholder={placeholder}
          multiline
          resizable={false}
          borderless
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onEnterPress}
        />
      </div>

      {isLoading ? (
        <Stack
          horizontal
          className="text-white absolute right-4 bottom-5 rounded-full border border-white flex flex-row items-center justify-center p-1"
          role="button"
          aria-label="Stop generating"
          tabIndex={0}
          onClick={stopGenerating}
          onKeyDown={e =>
            e.key === 'Enter' || e.key === ' ' ? stopGenerating() : null
          }
        >
          <SquareRegular className="text-white" aria-hidden="true" />
        </Stack>
      ) : (
        <button
          id="send-button"
          className="absolute right-4 bottom-5"
          tabIndex={0}
          aria-label="Ask question button"
          onClick={sendQuestion}
          onKeyDown={e =>
            e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null
          }
        >
          {sendQuestionDisabled ? (
            <SendRegular className="w-6 h-5 text-white cursor-not-allowed" />
          ) : (
            <>
              <div className="absolute bottom-0 top-5 right-1 -mr-3 text-xs text-gray-400">
                {question.length}/150
              </div>
              <img src={Send} alt="Send button" className="w-5" />
            </>
          )}
        </button>
      )}
    </Stack>
  );
};
